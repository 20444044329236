main {
  background-color: #f5f5f5;
  padding: 2rem 0;
  &.refresh {
    animation: refresh 1s;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    .stock {
      background-color: #fff;
      border-radius: 0.5rem;
      padding: 2rem;
      .stock-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .stock-header-left {
          h1 {
            font-size: 2rem;
            margin-bottom: 0.5rem;
          }
          h2 {
            font-size: 1.5rem;
            color: #999;
          }
        }
        .stock-header-right {
          h1 {
            font-size: 2rem;
            margin-bottom: 0.5rem;
          }
          h2 {
            font-size: 1.5rem;
            color: #999;
          }
          .increase {
            color: #28a745;
          }
          .decrease {
            color: #dc3545;
          }
        }
      }
      .stock-chart {
        margin: 2rem 0;
      }

      .stock-buy {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
      
        .stock-buy-left {
          h1 {
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
          }
      
          h2 {
            font-size: 1rem;
            font-weight: 400;
            color: #6c757d;
          }
        }
      
        .stock-buy-right {
          display: flex;
          align-items: center;
          flex-direction: column;

          // childeren of stock-buy-right have a margin of 1rem
          > * {
            margin: 0.5em;
          }
      
          .stock-buy-amount {
            display: flex;
            flex-direction: column;
            align-items: center;

            label {
              font-size: 0.75rem;
              font-weight: 500;
              color: #6c757d;
              margin-bottom: 0.25rem;
            }
      
            input {
              width: 5rem;
              padding: 0.25rem;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              font-size: 1rem;
              font-weight: 500;
              text-align: center;
            }
          }
      
          button {
            padding: 0.5rem 1rem;
            border-radius: 0.25rem;
            font-size: 1rem;
            font-weight: 500;
            margin: 0.5rem;
            cursor: pointer;
          }
      
          .btn-primary {
            background-color: #007bff;
            color: #fff;
            border: none;
      
            &:hover {
              background-color: #0069d9;
            }
          }

          .btn-danger {
            background-color: #dc3545;
            color: #fff;
            border: none;
      
            &:hover {
              background-color: #bd2130;
            }
          }
        }
      }

      .stock-social {
        display: flex;
        justify-content: flex-end;
        a {
          margin-left: 1rem;
          color: #999;
          font-size: 1.5rem;
        }
      }
    }
  }
}